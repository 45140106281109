import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import keyUpHandler from '../../utils/keyUpHandler';
import SubscribeDialogCTA from '../SubscribeDialogCTA';
export default function () {
    function onKeyUp1(event) {
        keyUpHandler(event, this.props.onClose);
    }
    return React.createElement('div', {
        'id': 'SubscriptionBanner',
        'className': _.transform({
            'is-mobile': this.props.isMobile,
            'user-not-verified': this.props.userIsAuthenticated && !this.props.userIsVerified
        }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, React.createElement('div', { 'className': 'wrapper' }, React.createElement('span', { 'className': 'info' }, React.createElement('b', {}, 'Start your free 7-day trial'), ' and begin learning the art & technology of character creation from Award-winning industry professionals.\n    '), React.createElement(SubscribeDialogCTA, {}, React.createElement('div', { 'className': 'cta-subscription-btn btn -small -border-orange' }, React.createElement('span', {}, this.props.isMobile ? 'Start my free 7-day trial' : 'Start My Free Trial'))), React.createElement('i', {
        'aria-label': 'Close',
        'className': 'icon-close',
        'onClick': () => this.props.onClose(),
        'onKeyUp': onKeyUp1.bind(this),
        'tabIndex': '0'
    })));
}