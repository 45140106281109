/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types'
import React from 'react'

import template from './template.rt'
import './styles.css'

class TutorialMaterials extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showFullMaterials: false }

    this.toggleFullMaterials = this.toggleFullMaterials.bind(this)
  }

  toggleFullMaterials() {
    this.setState({ showFullMaterials: !this.state.showFullMaterials })
  }

  render() {
    return template.call(this)
  }
}

TutorialMaterials.propTypes = {
  materials: PropTypes.string.isRequired,
  referenceMaterials: PropTypes.string
}

TutorialMaterials.defaultProps = {
  referenceMaterials: ''
}

export default TutorialMaterials
