import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { courseCategoryLandingPageStatus } from 'reduxModules/courseCategoryLandingPage'
import { courseCategoryLandingPageStateSelector as stateSelector } from 'reduxModules/courseCategoryLandingPage/selectors'
import { courseCategoryLandingPageDispatchers as dispatchers } from 'reduxModules/courseCategoryLandingPage/dispatchers'

import ItemResultPropTypes from '../NewSearch/propTypes'
import { TagPropTypes } from '../NewSearch/Tag'

import template from './template.rt'
import './styles.css'

export class CourseCategoryLandingPage extends Component {
  componentDidMount() {
    this.props.closeBannerSubscription()
    this.props.loadCourseCategoryLandingPage()
  }

  componentWillUnmount() {
    this.props.clearCourseCategory()
  }

  render() {
    if (this.props.status === courseCategoryLandingPageStatus.success)
      return template.call(this)
    return null
  }
}

export const CourseCategoryLandingPagePropType = {
  // --
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  socialUrl: PropTypes.string.isRequired,
  // --
  metaDescription: PropTypes.string,
  metaKeywords: PropTypes.string,
  metaTitle: PropTypes.string,
  // --
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  photo: PropTypes.shape({
    original: PropTypes.string.isRequired,
    default: PropTypes.string.isRequired
  }),
  facebookShareImages: PropTypes.arrayOf(
    PropTypes.shape({
      default: PropTypes.string.isRequired,
      thumb: PropTypes.string.isRequired,
      facebook: PropTypes.string.isRequired,
      original: PropTypes.string.isRequired
    })
  )
}

CourseCategoryLandingPage.propTypes = {
  // from selectors
  ...CourseCategoryLandingPagePropType,
  status: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  hasSubscription: PropTypes.bool,
  usedFreeTrial: PropTypes.bool,
  searchSelectors: PropTypes.shape({
    loading: PropTypes.number.isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape(TagPropTypes)).isRequired,
    results: PropTypes.arrayOf(PropTypes.shape(ItemResultPropTypes)).isRequired,
    status: PropTypes.string.isRequired,
    active: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  // from dispatchers
  closeBannerSubscription: PropTypes.func.isRequired,
  loadCourseCategoryLandingPage: PropTypes.func.isRequired,
  searchDispatchers: PropTypes.shape({
    getResults: PropTypes.func.isRequired,
    selectCategory: PropTypes.func.isRequired,
    clearResults: PropTypes.func.isRequired
  }).isRequired
}

CourseCategoryLandingPage.defaultProps = {
  isMobile: false,
  hasSubscription: false,
  usedFreeTrial: false,
  metaDescription: '',
  metaKeywords: '',
  metaTitle: ''
}

export default connect(stateSelector, dispatchers)(CourseCategoryLandingPage)
