import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
export default function () {
    function onClick1(props) {
        this.CTAAction();
    }
    function repeatFeature2(props, feature, i) {
        return React.createElement('li', {
            'className': _.transform({ 'plan-features-list__item--highlight': typeof feature === 'object' && feature.isHighlight }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ') + ' ' + 'plan-features-list__item',
            'key': 'feature-' + props.id + '-' + i
        }, '\n        \u2714 ', typeof feature === 'string' ? feature : feature.text, '\n      ');
    }
    function onClick3(props) {
        this.toggleFeatures();
    }
    function scopeProps4() {
        var props = this.props;
        return React.createElement('div', { 'className': 'plan-box' + (props.isMostPopular ? ' plan-box--popular' : '') }, props.isMostPopular ? React.createElement('p', {
            'className': 'plan-box__pop-badge',
            'key': '156'
        }, 'Most Popular') : null, React.createElement('h3', { 'className': 'plan-box__name' }, props.name), React.createElement('p', { 'className': 'plan-box__price' }, props.priceString), React.createElement('p', { 'className': 'plan-box__description' }, props.description), React.createElement('button', {
            'className': 'plan-box__cta',
            'onClick': onClick1.bind(this, props)
        }, '\n    ', this.getCTA(), '\n  '), this.state.showFeatures ? React.createElement('div', {
            'className': 'plan-features',
            'key': '491'
        }, React.createElement('h4', { 'className': 'plan-features__title' }, 'Features'), React.createElement.apply(this, [
            'ul',
            { 'className': 'plan-features-list' },
            _.map(props.features, repeatFeature2.bind(this, props))
        ])) : null, React.createElement('button', {
            'className': 'plan-box__feat-cta',
            'onClick': onClick3.bind(this, props)
        }, '\n    ', this.state.showFeatures ? '- Hide' : '+ Show', ' features\n  '));
    }
    return scopeProps4.apply(this, []);
}