/* global API_ROOT */
/* eslint-disable import/prefer-default-export */
import { initialize } from 'redux-form'
import {
  startStatisticsPost,
  sendTickStatistics,
  clearStatistics
} from 'reduxModules/statistics'
import { addVideoSeen } from 'reduxModules/auth'
import {
  setChapterAsCompleted,
  createBookmark,
  types as tutorialTypes,
  tutorialFetchRecommends
} from 'reduxModules/tutorials'
import { dispatchRemoveBookmark } from 'reduxModules/tutorials/dispatchers'
import _ from 'lodash'
import {
  setCurrentChapter,
  hideAddBookmark,
  showAddBookmark,
  removeLoadedBookmark,
  toggleRelevants,
  toggleRelevantChapters,
  cleanPlayer,
  toggleChapters,
  updateUserProgress
} from './'
import notifications, {
  toastError,
  toastServerError,
  toastSuccess
} from '../../../global/notifications'

const bookmarksVisibilityHandler = dispatch => ({
  displayCreateBookmark(chapter = null) {
    dispatch(showAddBookmark())
    if (chapter)
      dispatch(initialize('add-bookmark', { bookmarkName: chapter.extra.name }))
  },
  hideCreateBookmark() {
    dispatch(hideAddBookmark())
  }
})

export const playerDispatchers = dispatch => ({
  setCurrentChapter(videoId, currentChapterIndex, currentChapterId) {
    dispatch(
      setCurrentChapter({ videoId, currentChapterIndex, currentChapterId })
    )
  },
  startTutorialStatistics(tutorial, path) {
    dispatch(startStatisticsPost({ tutorial, path }))
  },
  sendTick(videoStatisticId, chapterId, currentPosition) {
    dispatch(sendTickStatistics(videoStatisticId, chapterId, currentPosition))
  },
  clearStatistics() {
    dispatch(clearStatistics())
  },
  addVideoSeen(video) {
    dispatch(addVideoSeen(video))
  },
  markChapterAsCompleted(videoId, chapterId) {
    dispatch(setChapterAsCompleted(videoId, chapterId))
  },
  displayRelevants() {
    dispatch(toggleRelevants())
  },
  loadNextTutorials(tutorial, pathId) {
    dispatch(tutorialFetchRecommends(tutorial, pathId)())
  },
  cleanPlayer() {
    // TO DO check after QA if everything is working without clearTutorial method
    // dispatch(clearTutorial())
    dispatch(cleanPlayer())
  },
  toggleChaptersSidebar() {
    dispatch(toggleChapters())
  },
  toggleShowingOnlyRelevantChapters() {
    dispatch(toggleRelevantChapters())
  },
  removeLoadedBookmark: () => dispatch(removeLoadedBookmark()),
  ...dispatchRemoveBookmark(dispatch),
  ...bookmarksVisibilityHandler(dispatch),
  updateUserProgress: ({ id, currentPosition, completed, duration }) =>
    dispatch(updateUserProgress({ id, currentPosition, completed, duration }))
})

export const addBookmarkDispatchers = (dispatch, props) => ({
  onSubmit(data) {
    let name
    const { tutorial, chapter, getCurrentTime } = props
    let chapterId

    if (_.has(chapter, 'extra.id')) chapterId = chapter.extra.id
    else {
      toastError(notifications.bookmarkCreated)
      return
    }

    if (_.isEmpty(data.bookmarkName) && _.has(chapter, 'extra.name'))
      name = chapter.extra.name
    else name = data.bookmarkName

    dispatch(
      createBookmark({
        name,
        tutorial,
        chapter: chapterId,
        time: parseInt(getCurrentTime(), 10)
      })
    )
      .then(([_, res]) => {
        if (res.type === tutorialTypes.TUTORIAL_CREATE_BOOKMARK_SUCCESS) {
          toastSuccess(notifications.bookmarkCreated)
          dispatch(hideAddBookmark())
        } else toastError(notifications.bookmarkCreated)
      })
      .catch(payload => {
        const error = payload.errors
        toastServerError(error)
      })
  },
  ...bookmarksVisibilityHandler(dispatch)
})
