import _ from 'lodash'

export const members = [
  {
    groupName: 'Management Team',
    groupMembers: [
      {
        name: 'Matt Winston',
        slug: 'matt-winston',
        position: 'Co-Founder',
        portraitImage: '../../global/assets/images/members/mwinston.jpg',
        landscapeImage:
          '../../global/assets/images/members/landscape/mwinston.jpg',
        bio:
          '<p>Matt Winston, son of Special Effects wizard Stan Winston, grew up in a world of fantasy ' +
          'characters and creature effects.</p><p>While in high school, Matt began working at Stan Winston Studio and learning the FX ropes. ' +
          'This led to puppeteering work on projects that would become iconic cinema classics such as James Cameron’s Aliens and Steven Spielberg’s Jurassic Park.</p>' +
          "<p>After graduating from Yale University, Matt returned to his father's workshop to learn CGI in the fledgling SWS digital department before Stan " +
          'joined forces with James Cameron and former ILM boss, Scott Ross to form the visual effects company Digital Domain.</p>' +
          '<p>While early ambitions as an actor led his father to character effects work, Matt took the opposite path and after starting out in effects, ' +
          'began to develop characters as an actor and later as a screenwriter. His acting credits include memorable roles in Six Feet Under, ' +
          'Little Miss Sunshine, About Schmidt, Scrubs and David Fincher’s now cult classic Fight Club. He also co-wrote the feature film Thanks for Sharing, ' +
          'starring Mark Ruffalo, Gwyneth Paltrow, Tim Robbins & Alecia Moore (Pink), which premiered at the Toronto Film Festival followed by a worldwide theatrical release.</p>' +
          "<p>Family has always been foremost for Matt, and he is proud to be honoring his father's legacy of inspiration and creativity through the Stan Winston School of Character Arts.</p>"
      },
      {
        name: 'Erich Grey Litoff',
        slug: 'erick-grey-litoff',
        position: 'Co-Founder',
        portraitImage: '../../global/assets/images/members/egrey.jpg',
        landscapeImage:
          '../../global/assets/images/members/landscape/egrey.jpg',
        bio:
          '<p>Entrepreneur and polymath&nbsp;Erich Grey Litoff found his way to Hollywood to pursue a career in the Entertainment Industry after serving in the United States Air Force during the Gulf War. &nbsp;&nbsp;</p>' +
          '<p>Born and raised in Greenwich Village, New York, where the family business was jazz clubs and restaurants, Erich developed his professional skills&nbsp;early in life, working&nbsp;his way up from making Bloody ' +
          'Mary mixes for the bar at age 10, to managing a club at age 18.&nbsp;After his term in the Air Force,&nbsp;Erich sold premium foods door-to-door, captained a country club, launched a motivational seminar ' +
          'enterprise&nbsp;and finally moved west with an eye toward&nbsp;working in the&nbsp;entertainment industry. &nbsp;</p>' +
          '<p>Erich&rsquo;s passion for business and entertainment grew through his evolution from prop master and art director to development exec, ultimately contributing to the production of over 600 television shows, ' +
          ' commercials and feature films.</p>' +
          '<p>Most recently, Erich has focused his business and production expertise as co-founder of the various enterprises in the name of his late father-in-law, legendary Special Effects wizard, Stan Winston.</p>'
      },
      {
        name: 'Jake Borowski',
        slug: 'jake-borowski',
        position: 'Head of Production',
        portraitImage: '../../global/assets/images/members/jborowski.jpg',
        landscapeImage:
          '../../global/assets/images/members/landscape/jborowski.jpg',
        bio:
          '<p>Upon watching behind the scenes features for Jurassic Park and the Back to the Future trilogy at around 10 years old, it dawned on Jake that a human could grow up and participate in the making of a movie.</p>' +
          '<p>After working up from a set P.A. to predominantly camera and post-production, which happens to be the nature of his work at Stan Winston School, Jake has most recently grown into writing and producing films in his off time.</p>'
      },
      {
        name: 'David Sanger',
        slug: 'david-sanger',
        position: 'Production Consultant',
        portraitImage: '../../global/assets/images/members/dsanger.jpg',
        landscapeImage:
          '../../global/assets/images/members/landscape/dsanger.jpg',
        bio:
          '<p>David, the son of Oscar-winning producer Jonathan Sanger, grew up in Los Angeles where he attended high&nbsp;school with Stan Winston&rsquo;s son Matt.&nbsp;David and Matt have been ' +
          'lifelong friends and the stories of their juvenile hijinks are the stuff of legend.</p>' +
          '<p>David is currently Head of Production at New Deal Studios where he has produced practical visual effects for countless commercials, special projects and sequences on more than 80 ' +
          'different feature films including such Oscar-winning titles as, THE DEPARTED, HUGO, INCEPTION, LIFE OF PI, and most recently, INTERSTELLAR. He has been with the Stan Winston School ' +
          'since its founding and is proud to have known and been inspired by Stan personally for many years.</p>' +
          '<p>David likes strong coffee, interesting socks, and giraffes. David lives in Studio City with his wife, two sons, a dog, a gecko and at least one, but sometimes several, fish.</p>'
      }
    ]
  },
  {
    groupName: 'Production Staff',
    groupMembers: [
      {
        name: 'Andrew Paul',
        slug: 'andrew-paul',
        position: 'Editor',
        portraitImage: '../../global/assets/images/members/apaul.jpeg',
        landscapeImage: '../../global/assets/images/members/landscape/apaul.jpeg',
        bio:
          '<p>Editor, photographer, and musician, Andrew Paul was born and raised in Southern California where he studied filmmaking at CSULB.  </p>' +
          '<p>In his free time he likes to experiment with film photography and plays in numerous bands around the Los Angeles area. </p>'
      },
      {
        name: 'Ryan Cultrera',
        slug: 'ryan-cultrera',
        position: 'Camera Operator',
        portraitImage: '../../global/assets/images/members/rcultrera.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          '<p>Ryan comes from the small town of Eliot, ME. At age 6, he was shown the movie, Jurassic Park, and became obsessed with the dinosaurs. As time went on, his focus shifted to how they made them. Turns out, they were done by a guy by the name of Stan Winston. </p>' +
          '<p>This was his first dive in to learning how movies were made, and the spark that lead to his goal of being a filmmaker. 20 years later, he would go on to be a camera operator for the SWSCA (and is still obsessed with dinosaurs). </p>' +
          '<p>In his free time, Ryan writes and directs his own short films for Smalltown Pictures, and is the host and creator of the Joblo Movie Network’s series, "Where It Was Made". He also edits movie trailers professionally. His goal is to some day make a film that inspires the same level of awe that JP gave him when he was a kid.</p>'
      },
      {
        name: 'Nick Fortunato',
        slug: 'nick-fortunato',
        position: 'Production Sound Mixer',
        portraitImage: '../../global/assets/images/members/nfortunato.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          "At a young age Nick realized that he wanted to work within the entertainment industry. When not helping out at the Stan Winston School of Character Arts, he's religiously rewatching his favorite 90's Nickledeon shows or writing his own scripts. "
      },
      {
        name: 'Roland Nikolic',
        slug: 'roland-nikolic',
        position: 'Quality Assurance',
        portraitImage: '../../global/assets/images/members/rnikolic.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          "A true jack-of-all-trades and master of some. Next to a Degree in Musical Composition and mad guitar skills, Roland has a passion for anything creative. Fascinated at an early age by the screen magic of masters like Stan Winston, Dick Smith, Rick Baker, Steve Johnson and Jim Henson he's been dabbling in makeup, costumes and props ever since." +
          "Once upon a time he learned about fantastical teachers and where to find them: the Stan Winston School of Character Arts. Then in late 2015 there came a time they needed help. First job? Creating subtitles and their translations, but being a sucker for detail his second task awarded was quality control for video courses. Thus he's making sure all students enjoy the best educational content in the industry at max fidelity. " +
          'Roland is a resident of Groningen, the Netherlands. Enjoying life as a creative freelancer and 3D printing consultant with his cherished dogs Allison and Sofie. He loves building movie props, cosplay, gaming, anime and pen & paper RPG. His fave composer is Danny Elfman and you can always wake him for epic sushi or baklava with a tight espresso.'
      },
      {
        name: 'Jason Brown',
        slug: 'jason-brown',
        position: 'Production',
        portraitImage: '../../global/assets/images/members/jbrown.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          'Originally hailing from the Washington, DC metro area Jason is an audio professional with over a decade of experience with his Music Production and DJ alias Qdup. He has many music productions and remixes under his belt with well received independent record labels such as Fort Knox Recordings, ESL Music, Super Hi-Fi Records and Bombstrikes to name a few.' +
          ' He has also DJ’d for years including club residency’s in DC hot spots like Eighteenth Street Lounge and Tropicalia. He has also toured as a DJ through North America including music festivals such as Shambhala Music Festival (BC, Canada), Firefly Fest (DE, USA), Art Outside (TX, USA), Gathering of the Vibes (Ct, USA), BassCoast (Merritt, Canada), Boogaloo (CA, USA), Sonic Bloom (CO), Northern Nights (CA, USA) and P.E.X. (MD, USA).' +
          ' Over the past couple of years while in Los Angeles, Jason has grown his audio work in post production including projects in sound design for library music for Canada’s Black Octopus Sounds as well as working on projects mixing sound for TV commercials and now with the Stan Winston School. He’s fascinated with the movie FX industry and enjoys being able to apply his audio mixing with the Stan Winston School.'
      },
      {
        name: 'Stephanie Bullington',
        slug: 'stephanie-bullington',
        position: 'Camera Operator',
        portraitImage: '../../global/assets/images/members/sbullington.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          '<p>Stephanie was raised in the Mojave Desert of Southern California by deaf parents. She is an artist of many mediums, though sculpture is her favorite. She is a musician/singer-song writer, a mother to a brilliant and fearless daughter, and wife to her best friend. A mini-orchard and garden keep her sane while her passion for writing takes her to the brink of insanity.</p>' +
          '<p>She is thrilled, honored, and humbled to be a part of Stan Winston School of Character Arts: a virtual school where masters bestow powerful, invaluable tools directly to the artist! Tools that let you pull from the ether and breathe life into dinosaurs, or weave nightmares and dreams, and elicit wonder in the heart of man. Get your superpowers here!</p>'
      }
    ]
  },
  {
    groupName: 'Marketing/Sales Staff',
    groupMembers: [
      {
        name: 'Andy Franco',
        slug: 'andy-franco',
        position: 'VP of Marketing',
        portraitImage: '../../global/assets/images/members/afranco.jpg',
        landscapeImage:
          '../../global/assets/images/members/landscape/afranco.jpg',
        bio: ''
      },
      {
        name: 'Nakisha Hester',
        slug: 'nakisha-hester',
        position: 'Enterprise Account Executive',
        portraitImage: '../../global/assets/images/members/nhester.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          '<p>Nakisha is an East Coast girl who fell in love with the creative and laid back culture of California.  A Natural Communicator, Nakisha builds positive relationships with all types of people.  It is in her nature to go above and beyond in everything she does.  Always wanting to bring a good experience to everyone she meets. ' +
          'Nakisha brings a strong background in customer relations, educational software sales, and an unstoppable desire to rise to the occasion.</p>' +
          '<p>She has always been passionate about the Make-up Arts and that is one of the main things that drew her to SWSCA.  The most important thing that attracted her, however, is her desire to be a part of the incredibly dynamic and genuine team that are found here. A rare gem indeed!</p>' +
          '<p>She enjoys reading, creating natural beauty formulas, and spending quality time with family and friends. </p>'
      },
      {
        name: 'Jon Robertson',
        slug: 'john-robertson',
        position: 'Group Sales & Marketing Consultant',
        portraitImage: '../../global/assets/images/members/jrobertson.jpg',
        landscapeImage:
          '../../global/assets/images/members/landscape/jrobertson.jpg',
        bio:
          '<p>Jon married into a family of artists, comic book collectors, and movie buffs back in 2002.&nbsp;After more than ten years of dinner conversations ranging from superhero origin stories, spirited debates over which Batman director ' +
          'was best, and the 103 things about Galaxy Quest that most people missed, he now has a PhD equivalent in film appreciation &ndash; all of which makes him uniquely unqualified for his role at the Stan Winston School.<br /></p>' +
          '<p>During a brief moment of perfect planet alignment in 2014, Jon was introduced to the Stan Winston School team through a mutual friend and was immediately adopted as part of the family.&nbsp;Despite his lack of actual ' +
          'filmmaking experience, Jon has found a way to provide value in the areas he does know about, including sales, marketing, product development, and business strategy.</p>' +
          '<p>Jon currently splits his time between his home in Ventura and major airports across the country.&nbsp;He loves great food and wine, and then trying to burn off those calories on his road bike.</p>'
      },
      {
        name: 'Samantha Westervelt',
        slug: 'samantha-westervelt',
        position: 'Email Marketing Manager',
        portraitImage: '../../global/assets/images/members/swestervelt.png',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          'Samantha Westervelt is the Stan Winston School of Character Arts Email Marketing Manager. When she isn’t crafting ways to keep you up to speed with all things SWSCA, she spends her time as the lead singer and bass player of LA-based punk trio Egg Drop Soup.'
      }
    ]
  },
  {
    groupName: 'Customer Care',
    groupMembers: [
      {
        name: 'Amy Winston',
        slug: 'amy-winston',
        position: 'Customer Care',
        portraitImage: '../../global/assets/images/members/awinston.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          'As the daughter-in-law of Stan Winston, Amy is proud to be a member of the Stan Winston School Customer Care team, and enjoys answering student questions and solving any issues they encounter while studying at our virtual school. When not helping out at SWSCA, Amy enjoys pursuing her other passions, including acting and animal rescue. '
      },
      {
        name: 'Debbie Winston Litoff',
        slug: 'debbie-winston-litoff',
        position: 'Customer Care',
        portraitImage: '../../global/assets/images/members/dwinstonl.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          'Born and raised in Los Angeles, California, Debbie is a self-taught artist. She has been painting modern abstracts for 15 years. Her late father Stan Winston was known for creating amazing characters/creatures for iconic films. In addition to his love of the arts, family and education were two of the most important things to him.' +
          " Debbie believes that celebrating artists and giving them a platform to teach and inspire people all over the world to imagine, learn and create is the perfect way to keep her father's spirit alive."
      }
    ]
  },
  {
    groupName: 'Social Media',
    groupMembers: [
      {
        name: 'Balázs Földesi',
        slug: 'balazs-foldesi',
        position: 'Director of Social Media',
        portraitImage: '../../global/assets/images/members/bfoldesi.jpg',
        landscapeImage:
          '../../global/assets/images/members/landscape/bfoldesi.jpg',
        bio:
          '<p>Bal&aacute;zs grew up on Stan Winston&rsquo;s monsters and he is one of the biggest fans of Stan Winston School from the beginning. Originally Bal&aacute;zs worked as an IT specialist in Hungary and as a fan, he&nbsp;helped the school on Facebook' +
          'where he could. He soon became friends with&nbsp;Matt Winston and then the whole team. In 2012, Bal&aacute;zs began working for Stan Winston School and is now one of its lead&nbsp;social media&nbsp;administrators. He is also&nbsp;a&nbsp;Stan Winston School&nbsp;website&nbsp;administrator ' +
          'and enjoys putting together blogs for the community. Almost all of his ideas have been completed due to the flexibility of the team. He feels very lucky as the Stan Winston School is not just a job for him; it is his hobby and his second family.</p>' +
          '<p>Bal&aacute;zs lives in Budapest, Hungary with his girlfriend, Adrienn. Beside all kind of special effects and fan arts, Bal&aacute;zs likes vacuuming, taking pictures everywhere he goes but mainly at sunset, street art, drinking coffee early morning in nature, ' +
          'dogs in any quantity and mixing electronic music.</p>'
      },
      {
        name: 'Chris Ellerby',
        slug: 'chris-ellerby',
        position: 'Forum Administrator',
        portraitImage: '../../global/assets/images/members/cellerby.jpg',
        landscapeImage:
          '../../global/assets/images/members/landscape/cellerby.jpg',
        bio:
          '<p>Technology Director by day, Special Effects Artist by night.&nbsp;When Chris is not slinging code and developing technology solutions for companies like Disney he is busy crafting creatures for his special effects company, Vex FX.&nbsp;If he&rsquo;s not making a mess in the lab you ' +
          'can find him hanging out in the Stan Winston School of Character Arts Forums.</p>'
      },
      {
        name: 'Adrienn Lingurár',
        slug: 'adrienn-lingurar',
        position: 'Social Media/Operations Assistant',
        portraitImage: '../../global/assets/images/members/alingurar.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          "<p>The 1994 Academy Award was a life changer for Adrienn. Till that year, as a kid, she truly believed that somewhere on the planet, there was a park with dinos. And then, on the 21st of March, 1994, a T-Rex came on stage at the Academy Awards; Elijah Wood fearlessly took the envelope out of its mouth and read the winners for Best Visual Effects. Adrienn’s jaw dropped.   At that moment, she realized that being afraid of eggs because of alien facehuggers didn't make sense. Liquid metal won't become a T-1000; Predators won't come to Earth to hunt; the Penguin is not the leader of her favorite animals; and most importantly, there is no Jurassic Park she can visit to feed a T-Rex and pet Brachiosauruses.</p>" +
          "<p>Each of these creatures had a MASTERMIND behind them, a true wizard who tricks people. His name is Stan Winston. Since that day, Adrienn has been very analytical. She analyzes everything she sees or works on, and it's come in handy at Stan Winston School. Whether it's a spreadsheet, research, or assisting with social media, working together with the family of the MAN, whose creatures and characters played a significant role in her life always comes to her mind and motivates her in every minute; writing behind-the-scenes blogs about the movies Stan worked on makes it even more incredible.</p>" +
          '<p>Adrienn lives with SWSCA Social Media Director Balázs Földesi. When not working for SWSCA, she is interested in analog photography, street art, neolithic & bronze age archeology, and astronomy; she also believes in lifelong learning and - just like Stan - loves doing workouts.</p>'
      }
    ]
  },
  {
    groupName: 'Website Development',
    groupMembers: [
      {
        name: 'Pedro Piñango',
        slug: 'pedro-pinango',
        position: 'Project Manager',
        portraitImage: '../../global/assets/images/members/ppinango.png',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          'Pedro Piñango is an entrepreneur with a BS in Computer Engineering from Simon Bolivar University in Venezuela. He has been part of management teams of Startups during the acceleration process in recognised programs such as Wayra, Startup Chile and Techstars. Pedro is always looking for the right problem to solve, joining great people with the right tools to do it.'
      },
      {
        name: 'Gustavo Siñovsky',
        slug: 'gustavo-sinovsky',
        position: 'Development Lead',
        portraitImage: '../../global/assets/images/members/gsinovsky.jpg',
        landscapeImage:
          '../../global/assets/images/members/landscape/gsinovsky.jpg',
        bio:
          '<p>Gustavo Siñovsky is a Computer Engineer specialized in management and development of web-based  applications. On his spare time you will find him listening to the latest in electronic music, or mixing it himself as an aspiring DJ.</p>'
      }
    ]
  },
  {
    groupName: 'SWS Archives',
    groupMembers: [
      {
        name: 'Erik Rose',
        slug: 'erick-rose',
        position: 'Director of the Stan Winston Archive',
        portraitImage: '../../global/assets/images/members/erose.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          '<p>Erik has been a life long fan of film, television, special effects, and art. In the process of establishing himself as a full-time illustrator he lucked into a job as an archivist at 20th Century Fox.</p>' +
          '<p>It was there that he met the Winston Family and started a relationship that culminated in working at the Stan Winston Archive.</p>' +
          '<p>While archiving the decades of artwork, photography, and video created by Stan’s amazing studio Erik often finds himself flashing back to seeing these amazing creations on the screen for the first time.</p>' +
          '<p>He often has to pinch himself to make sure he’s actually here while hearing the voice of his ten year old self gasp “Whoa”.' +
          '<p>He feels an incredible sense of responsibility to take care of and preserve Stan’s place in history as one of the most influential artists to ever work in the business. And it’s a dream job.</p>' +
          '<p>Erik splits his time between directing the archive and his career as an illustrator of posters for TV and Film. He has created artwork for Vice Principals, Game of Thrones, The Simpsons, Spider-Man, The Walking Dead, American Horror Story, Big Mouth, Baskets, Winchester, and Fight of the Living Dead.</p>'
      },
      {
        name: 'Dimitar Dimitrov',
        slug: 'dimitar-dimitrov',
        position: 'Archival Assistant',
        portraitImage: '../../global/assets/images/members/ddimitrov.jpg',
        landscapeImage:
          '../../global/assets/images/members/landscape/ddimitrov.jpg',
        bio:
          '<p>From an early age, Dimitar grew up on a strict diet of horror books, monster movies and rock music.&nbsp;At the age of 10, he began his fantastic journey into the magic world of special effects and movie making due to his fascination with&nbsp;cinematic illusions and how they were made.</p>' +
          '<p>Inspired mainly by the work of character creator Stan Winston in many of his favorite films, including THE TERMINATOR, ALIENS, PREDATOR, TERMINATOR 2 JUDGMENT DAY,&nbsp;as well as&nbsp;the work of such iconic makeup artists and monster makers as Dick Smith, Rob Bottin, Rick Baker, Steve ' +
          'Johnson, Tom Savini, KNB EFX Group and Bob Keen, Dimitar is a&nbsp;passionative admirer of the art of makeup and people behind it.&nbsp;His passion for practical special effects makeup was also fueled by the teleseries MOVIE MAGIC, Fangoria Magazine and any book that he could&nbsp;get ' +
          'his teeth on.&nbsp;Some of his friends from the industry call him &quot;The Walking Encyclopedia of&nbsp;Special Effects and Filmmaking&quot;&nbsp;because of his rich knowledge about all the artists and technicians and their work.</p>' +
          '<p>Since the summer of 2014, Dimitar has been a part of the Stan Winston School of Character Arts family, first as a Social Media Administrator and now as an Archival Assistant, helping to tell the story of the craft to the next generation of makeup artists and creature creators.</p>' +
          '<p>Dimitar lives in Chirpan, Bulgaria, with his two dogs, Butch and Jimba. His fave author is Stephen King, his fave director is James Cameron, his fave band is Biohazard. He loves red wine, cats and dogs and the beauty of&nbsp;nature.</p>'
      }
    ]
  },
  {
    groupName: 'Writers',
    groupMembers: [
      {
        name: 'Michael Martin',
        slug: 'michael-martin',
        position: 'Website Blogger',
        portraitImage: '../../global/assets/images/members/mmartin.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          'Michael comes to the SWSCA team with a lifelong love of all things monsters and movies, an obsession lovingly fostered by understanding parents who reveled in the magic of film. ' +
          'A childhood spent animating clay dinosaurs, being covered in grease paint and baking foam latex would lead to a wide variety of theatre, film, television and radio experiences. Michael is a fan of Stan Winston, Rick Baker, Dick Smith and Tom Savini. An award-winning indie filmmaker, Michael currently focuses on writing low-budget screenplays and blogging for the Stan Winston School of Character Arts. ' +
          'Michael lives in Boise, Idaho with his wife Samantha, a National Public Radio anchor, reporter and producer and their colorful array of cats and birds.'
      },
      {
        name: 'Lexi Stewart',
        slug: 'lexi-stewart',
        position: 'Website Blogger',
        portraitImage: '../../global/assets/images/members/lstewart.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          'Lexi is a blogger and makeup artist working in film and television in Australia. She has formally trained as a graphic designer, but in 2015 decided to return to study and pursue work where she could explore her real passion: movie makeup effects. When not writing for the Stan Winston School or creating makeups, Lexi spends time curating her blog Violent Maiden, which helps makeup artists looking to work in film.'
      },
      {
        name: 'Yvonne Villasenor',
        slug: 'yvonne-villasenor',
        position: 'Website Blogger',
        portraitImage: '../../global/assets/images/members/yvillasenor.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          "<p>For as long as Yvonne can remember, she's loved writing, movies, music, art and Halloween. A daughter of cinephiles, she grew up fascinated by Stan Winston’s creations.</p>" +
          "<p>She was thrilled to discover at an early age that one of her favorite movies, Small Soldiers, was filmed in her hometown, which made her want real-life Gorgonite friends even more! Yvonne’s innate curiosity, appreciation for words and passion for learning — much like the Gorgonites' — led her to pursue a career in journalism.</p>" +
          "<p>Since then, she's interviewed people from all walks of life and shared their stories for readers to enjoy. In addition to being a journalist, she is also a copywriter and content writer. (Did she mention she loves to write?) When Yvonne’s not writing for SWSCA, you can find her cuddling her Chihuahuas, exploring Southern California and telling eye roll-worthy puns.</p>"
      }
    ]
  },
  {
    groupName: 'Bookkeeping',
    groupMembers: [
      {
        name: 'Amy Palanker',
        slug: 'amy-palanker',
        position: 'Bookkeeper',
        portraitImage: '../../global/assets/images/members/apalanker.jpg',
        landscapeImage: '../../global/assets/images/members/banner-alien.jpg',
        bio:
          'Amy Palanker has been happily working as a bookkeeper/accountant for clients in the Los Angeles-area for 35 years.'
      }
    ]
  }
]

export const findMember = slug => {
  let member
  members.some(group => {
    member = _.find(group.groupMembers, { slug })
    if (member) return true
    return false
  })
  return member
}
