/* global API_URL, dataLayer */
import _, { has as hasProperty } from 'lodash'
import { push } from 'react-router-redux'
import { scroller } from 'react-scroll'
import { cookie } from 'redux-effects-cookie'
import { change, untouch } from 'redux-form'

import logger from 'components/utils/logger'
import { navHandlers } from 'reduxModules/app/dispatchers'
import { dispatchDisplayRecaptcha } from 'reduxModules/captcha/dispatchers'
import modalDispatchers from 'reduxModules/generic/modal/dispatchers'
import { initConfirmation } from 'reduxModules/confirmation'
import { openProfile, closeProfile } from 'reduxModules/users'
import { cartItemsFetch } from 'reduxModules/cart'
import types from 'reduxModules/auth/types'
import { subscriptionSummaryFetch } from 'reduxModules/subscriptions'
import { clearRecaptchaToken, sendUTM } from 'reduxModules/app'
import { homeFetchCurrent } from 'reduxModules/home'

import {
  changePassword,
  forgotPassword,
  resetPassword,
  loginWithFacebook,
  loginWithMicrosoft,
  login,
  proxyLogin,
  openAthensLogin,
  signUp,
  cancelSubscription,
  clearSubscription,
  markSubscriptionCancellationPending,
  getUser,
  cancelFreeTrial,
  revokeFreeTrialPermission,
  updateGiftAvailability
} from './'
import notifications, {
  toastError,
  toastServerError,
  toastSuccess
} from '../../../global/notifications'

const sendUTMCallback = async dispatch => {
  const result = {}
  // TODO: change to let after fix the original problem
  // result = await retrieveAdData(dispatch)
  if (!_.isEmpty(result))
    dispatch(sendUTM(result.utm)).then(([_, res]) => {
      if (res.type === 'SET_UTM_SUCCESS')
        Object.keys(res.payload).forEach(value => {
          dispatch(cookie(`utm_${value}`, null, { path: '/' }))
        })
    })
}
const afterLoginCallback = (
  dispatch,
  props,
  subscription = {},
  inCheckout = false
) => {
  const { location } = props
  const next = props.next || (location && location.state && location.state.next)

  if (_.isNumber(subscription))
    dispatch(subscriptionSummaryFetch(subscription)())

  dispatch(cartItemsFetch())
  // google optimize event
  dataLayer.push({ isLoggedIn: true })

  if (next) dispatch(push({ pathname: next, query: location.query }))
  else if (!inCheckout) dispatch(push({ pathname: '/', query: location.query }))
}

export const dispatchLogin = (dispatch, data, props) => {
  dispatch(
    login(data.recaptcha)({ email: data.email, password: data.password })
  )
    .then(async res => {
      if (res[1].type === types.AUTH_LOGIN_SUCCESS) {
        dataLayer.push({
          event: 'GTMEvent',
          eventCategory: 'account-sign-in',
          eventAction: 'form-completion',
          eventLabel: 'email',
          userId: res[1].payload.user.id
        })

        const { isMobile, inCheckout } = props
        const subscription = _.has(res[1], 'payload.user.subscription')
          ? res[1].payload.user.subscription
          : {}

        if (
          inCheckout &&
          hasProperty(res[1].payload, 'user.role') &&
          res[1].payload.user.role === 'free_library_subscriber'
        ) {
          dispatch(push('/'))
          toastError(notifications.unableToPurchase)
        } else afterLoginCallback(dispatch, props, subscription, inCheckout)
        if (isMobile) scroller.scrollTo('shipTo', { offset: 64, smooth: true })
        toastSuccess(notifications.login)
        if (subscription) sendUTMCallback(dispatch)
      } else if (res[1]?.payload?.status === 412) {
        toastError(notifications.recaptcha)
        dispatchDisplayRecaptcha(dispatch)
      } else toastError(notifications.login)
    })
    .catch(() => {
      dispatch(change('signin', 'password', ''))
      dispatch(untouch('signin', 'password'))
      toastError(notifications.login)
    })
    .finally(() => dispatch(clearRecaptchaToken()))
}

export const dispatchProxyLogin = (dispatch, data, props) => {
  dispatch(proxyLogin({ ...data }))
    .then(async res => {
      if (res[1].type === types.AUTH_LOGIN_SUCCESS) {
        dataLayer.push({
          event: 'GTMEvent',
          eventCategory: 'account-sign-in',
          eventAction: 'form-completion',
          eventLabel: 'email',
          userId: res[1].payload.user.id
        })

        const { isMobile, inCheckout } = props
        const subscription = _.has(res[1], 'payload.user.subscription')
          ? res[1].payload.user.subscription
          : {}

        if (
          inCheckout &&
          hasProperty(res[1].payload, 'user.role') &&
          res[1].payload.user.role === 'free_library_subscriber'
        ) {
          dispatch(push('/'))
          toastError(notifications.unableToPurchase)
        } else afterLoginCallback(dispatch, props, subscription, inCheckout)
        if (isMobile) scroller.scrollTo('shipTo', { offset: 64, smooth: true })
        toastSuccess(notifications.login)
      } else toastError(notifications.login)
    })
    .catch(payload => {
      const error = payload.errors
      dispatch(change('signin', 'password', ''))
      dispatch(untouch('signin', 'password'))
      toastServerError(error)
    })
}

export const dispatchSignUp = (dispatch, data, props) => {
  dispatch(
    signUp(data.recaptcha)({
      email: data.email,
      password1: data.password1
    })
  )
    .then(([_, res]) => {
      if (res.type === types.AUTH_SIGNUP_SUCCESS) {
        const { inCheckout, isMobile, location } = props
        dataLayer.push({
          event: 'GTMEvent',
          eventCategory: 'account-creation',
          eventAction: 'form-completion',
          eventLabel: 'email',
          userId: res.payload.user.id
        })
        toastSuccess(notifications.signup)

        if (hasProperty(location, 'query.forum'))
          window.location.href = `${API_URL}/accounts/login/?next=/forum/return-forum`
        else if (!inCheckout) dispatch(push('/'))
        else if (isMobile)
          scroller.scrollTo('shipTo', { offset: 64, smooth: true })
        dataLayer.push({ isLoggedIn: true })
      } else if (res?.payload?.status === 412) {
        toastError(notifications.recaptcha)
        dispatchDisplayRecaptcha(dispatch)
      } else toastError(notifications.signup)
    })
    .catch(payload => {
      const error = payload.errors
      toastServerError(error)
    })
    .finally(() => dispatch(clearRecaptchaToken()))
}

export const dispatchFacebookLogin = (dispatch, accessToken, props) =>
  dispatch(loginWithFacebook({ access_token: accessToken })).then(res => {
    if (res[1].type === types.AUTH_FACEBOOK_LOGIN_SUCCESS) {
      try {
        dataLayer.push({
          event: 'GTMEvent',
          eventCategory: props.text.includes('register')
            ? 'account-creation'
            : 'account-sign-in',
          eventAction: 'form-completion',
          eventLabel: 'facebook',
          userId: res[1].payload.user.id
        })
      } catch (e) {
        logger.error('An error occurred after user signed in using Facebook', {
          error: e
        })
        throw e
      }
      const subscription = _.has(res[1], 'payload.user.subscription')
        ? res[1].payload.user.subscription
        : {}
      const { inCheckout } = props
      afterLoginCallback(dispatch, props, subscription, inCheckout)
      toastSuccess(notifications.login)
      if (subscription) sendUTMCallback(dispatch)
    } else toastError(notifications.login)
  })

export const dispatchMicrosoftLogin = (dispatch, accessToken, props) =>
  dispatch(loginWithMicrosoft({ access_token: accessToken })).then(res => {
    if (res[1].type === types.AUTH_MICROSOFT_LOGIN_SUCCESS) {
      try {
        dataLayer.push({
          event: 'GTMEvent',
          eventCategory: props.text.includes('register')
            ? 'account-creation'
            : 'account-sign-in',
          eventAction: 'form-completion',
          eventLabel: 'microsoft',
          userId: res[1].payload.user.id
        })
      } catch (e) {
        logger.error('An error occurred after user signed in using Microsoft', {
          error: e
        })
        throw e
      }
      const subscription = _.has(res[1], 'payload.user.subscription')
        ? res[1].payload.user.subscription
        : {}
      const { inCheckout } = props
      afterLoginCallback(dispatch, props, subscription, inCheckout)
      toastSuccess(notifications.login)
    } else toastError(notifications.login)
  })

export const dispatchForgotPassword = (dispatch, data) =>
  dispatch(forgotPassword(data)).then(([_, res]) => {
    if (res.type === types.AUTH_FORGOT_PASSWORD_SUCCESS) {
      toastSuccess(notifications.forgotPassword)
      dispatch(push('/'))
    } else toastError(notifications.forgotPassword)
  })

export const dispatchResetPassword = (dispatch, data, props) => {
  const payload = {
    new_password1: data.password1,
    new_password2: data.password2,
    token: props.params.token,
    uid: props.params.uid
  }
  dispatch(resetPassword(payload))
    .then(([_, res]) => {
      if (res.type === types.AUTH_RESET_PASSWORD_SUCCESS) {
        toastSuccess(notifications.passwordChange)
        dispatch(push('/'))
      } else toastError(notifications.passwordChange)
    })
    .catch(payload => {
      const error = payload.errors
      toastServerError(error)
    })
}

export const dispatchChangePassword = (dispatch, data) => {
  const payload = {
    new_password1: data.password1,
    new_password2: data.password2,
    old_password: data.oldPassword
  }
  dispatch(changePassword(payload))
    .then(([_, res]) => {
      if (res.type === types.AUTH_CHANGE_PASSWORD_SUCCESS) {
        toastSuccess(notifications.passwordChange)
        dispatch(push('/'))
      } else toastError(notifications.passwordChange)
    })
    .catch(payload => {
      const error = payload.errors
      toastServerError(error)
    })
}

export const onChangePasswordDispatch = dispatch => ({
  onSubmit: data => dispatchChangePassword(dispatch, data),
  ...navHandlers(dispatch)
})

export const onForgotPasswordDispatch = dispatch => ({
  onSubmit: data => dispatchForgotPassword(dispatch, data),
  ...navHandlers(dispatch)
})

export const onResetPasswordDispatch = (dispatch, props) => ({
  onSubmit: data => dispatchResetPassword(dispatch, data, props),
  ...navHandlers(dispatch)
})

export const onLoginBoxDispatch = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  return {
    ...stateProps,
    ...ownProps,
    onSubmit: data =>
      dispatchLogin(
        dispatch,
        { ...data, recaptcha: stateProps.recaptcha },
        ownProps
      )
  }
}

export const onAuthDispatch = dispatch => ({
  ...navHandlers(dispatch)
})

export const onSignupBoxDispatch = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  return {
    ...stateProps,
    ...ownProps,
    onSubmit: data =>
      dispatchSignUp(
        dispatch,
        { ...data, recaptcha: stateProps.recaptcha },
        ownProps
      )
  }
}

export const facebookLoginCallbackDispatch = (dispatch, props) => ({
  facebookLoginCallback: response =>
    dispatchFacebookLogin(dispatch, response.accessToken, props)
})

export const microsoftLoginCallbackDispatch = (dispatch, props) => ({
  microsoftLoginCallback: response =>
    dispatchMicrosoftLogin(dispatch, response.accessToken, props)
})

export const accountDispatch = dispatch => ({
  dispatchCancelSubscription: () => {
    const confirmation = {
      title: 'Sorry to see you go',
      text: 'What made you cancel?',
      options: [
        { resolveValue: 'accept', text: 'Continue', class: 'confirm-btn' }
      ],
      feedback: {
        options: [
          {
            text: 'Site speed too slow',
            value: 'Site speed too slow'
          },
          {
            text: 'Wanted to check us out',
            value: 'Wanted to check us out'
          },
          {
            text: "Can't afford it",
            value: "Can't afford it"
          },
          {
            text: 'Not enough value',
            value: 'Not enough value'
          },
          {
            text: 'Not enough time',
            value: 'Not enough time'
          },
          {
            text: 'Technical issue',
            value: 'Technical issue'
          },
          {
            text: 'Subscription plan upgrade',
            value: 'Subscription plan upgrade'
          },
          {
            text: 'Subscription plan downgrade',
            value: 'Subscription plan downgrade'
          },
          {
            text: 'Not using',
            value: 'Not using'
          },
          {
            text: 'Used what I needed',
            value: 'Used what I needed'
          },
          {
            text: 'No longer working in the field',
            value: 'No longer working in the field'
          },
          {
            text: 'Not what I thought I was getting',
            value: 'Not what I thought I was getting'
          },
          {
            text: 'Free trial ended',
            value: 'Free trial ended'
          },
          {
            text: 'Not enough courses in my field',
            value: 'Not enough courses in my field'
          },
          {
            text: 'Other',
            value: 'Other'
          }
        ]
      },
      height: 412,
      componentId: 'cancellation'
    }
    dispatch(initConfirmation(confirmation)).on('accept', feedback =>
      dispatch(
        cancelSubscription({ ...(!_.isEmpty(feedback) && { feedback }) })
      )
        .then(([_, res]) => {
          if (res.type === types.AUTH_CANCEL_SUBSCRIPTION_SUCCESS) {
            if (!res.payload.pendingChange) dispatch(clearSubscription())
            else dispatch(markSubscriptionCancellationPending())
            toastSuccess(notifications.cancelSubscription)
          } else toastError(notifications.cancelSubscription)
        })
        .catch(() => toastError(notifications.cancelSubscription))
    )
  },
  dispatchCancelFreeTrial: (forPromo = false, usedFreeTrial = false) => {
    const regularCancelationCopy = {
      title: 'Cancel free trial',
      text:
        'This will charge your payment method, do you want to cancel your free trial?',
      options: [
        { resolveValue: 'accept', text: 'Continue', class: 'confirm-btn' }
      ]
    }
    const promoCancellationCopy = {
      title: 'Payment confirmation',
      text:
        'This will charge your payment method. Do you want to pay for your subscription to be able to gift a matching subscription?',
      options: [
        { resolveValue: 'accept', text: 'Confirm', class: 'confirm-btn' }
      ]
    }
    const confirmation = forPromo
      ? promoCancellationCopy
      : regularCancelationCopy

    if (!usedFreeTrial)
      dispatch(initConfirmation(confirmation)).on('accept', () =>
        dispatch(cancelFreeTrial())
          .then(([_, res]) => {
            if (res.type === types.CANCEL_FREE_TRIAL_SUCCESS) {
              dispatch(revokeFreeTrialPermission())
              toastSuccess(notifications.freeTrialCancelled)
              if (forPromo) {
                toastSuccess(notifications.enabledToGift)
                dispatch(updateGiftAvailability())
                dispatch(push('/gift_subscription'))
              }
            } else toastError(notifications.freeTrialCancelled)
          })
          .catch(payload => {
            const error = payload.errors
            toastServerError(error)
          })
      )
    else dispatch(push('/gift_subscription'))
  },
  ...modalDispatchers(dispatch),
  openProfileSection() {
    dispatch(openProfile())
  },
  closeProfileSection() {
    dispatch(closeProfile())
  }
})

export const accountMenuDispatcher = dispatch => ({
  getUserInfo: () => dispatch(getUser())
})

export const watchHistoryDispatcher = dispatch => ({
  loadWatchHistory() {
    dispatch(
      homeFetchCurrent({
        auto_generated: true,
        full: true,
        ordering: '-id'
      })()
    )
  }
})

export const dispatchOpenAthensLogin = (dispatch, data, props) => {
  dispatch(openAthensLogin({ ...data }))
    .then(async res => {
      if (res[1].type === types.AUTH_LOGIN_SUCCESS) {
        dataLayer.push({
          event: 'GTMEvent',
          eventCategory: 'account-sign-in',
          eventAction: 'form-completion',
          eventLabel: 'email',
          userId: res[1].payload.user.id
        })

        const { isMobile, inCheckout } = props
        const subscription = _.has(res[1], 'payload.user.subscription')
          ? res[1].payload.user.subscription
          : {}

        if (
          inCheckout &&
          hasProperty(res[1].payload, 'user.role') &&
          res[1].payload.user.role === 'free_library_subscriber'
        ) {
          dispatch(push('/'))
          toastError(notifications.unableToPurchase)
        } else afterLoginCallback(dispatch, props, subscription, inCheckout)
        if (isMobile) scroller.scrollTo('shipTo', { offset: 64, smooth: true })
        toastSuccess(notifications.login)
      } else toastError(notifications.login)
    })
    .catch(payload => {
      const error = payload.errors
      dispatch(change('signin', 'password', ''))
      dispatch(untouch('signin', 'password'))
      toastServerError(error)
    })
}
