import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import { Field } from 'redux-form';
import { CaptchaNotice } from '../CaptchaNotice';
import { validators } from '../../../redux/modules/utils/forms';
import RenderedField from '../../generic/RenderedField';
import PasswordField from '../../generic/PasswordField';
import FacebookSignIn from '../../generic/FacebookSignIn/index';
import Scroll from 'react-scroll';
import CaptchaStandalone from '../../CaptchaStandalone/_lazy';
export default function () {
    return React.createElement('div', { 'id': 'RegisterBox' }, React.createElement('div', { 'className': 'sign-up-box auth-form-box' }, this.props.inCheckout ? React.createElement('button', {
        'className': 'toggle-box',
        'onClick': () => this.props.toggleAuth(),
        'key': '696'
    }, 'Login') : null, this.props.inCheckout ? React.createElement('div', {
        'className': 'title',
        'key': '820'
    }, React.createElement('div', { 'className': 'free-trial-text' }, !this.props.isGlobalPromoEnabled ? React.createElement('h3', { 'key': '918' }, 'Sign Up To Start Your Free 7-Day Trial') : null, this.props.isGlobalPromoEnabled ? React.createElement('h3', { 'key': '1017' }, 'Subscribe and Save ', this.props.globalPromo.discount, '% with code: ', this.props.globalPromo.codename) : null), React.createElement('div', { 'className': 'create-account' }, '\n          Create your account\n        ')) : null, React.createElement('form', { 'onSubmit': this.props.handleSubmit }, React.createElement(Field, {
        'name': 'email',
        'validate': [
            validators.required,
            validators.email
        ],
        'component': RenderedField,
        'type': 'email',
        'placeholder': 'Email',
        'autoFocus': this.props.autoFocus,
        'props': { ariaLabel: 'Email' }
    }), React.createElement(Field, {
        'name': 'password1',
        'validate': validators.required,
        'component': PasswordField,
        'type': 'password',
        'placeholder': 'Password',
        'props': { ariaLabel: 'Password' }
    }), React.createElement(CaptchaStandalone, {}), React.createElement('p', {
        'className': 'terms',
        'aria-label': 'By clicking Register you are agreeing to the Terms and Conditions and have read the Warnings and Risks'
    }, '\n          By continuing you are agreeing to the\n          ', React.createElement(Link, {
        'to': '/customer-care/terms-of-use',
        'target': '_blank'
    }, 'Terms and Conditions'), ' and have read the ', React.createElement(Link, {
        'to': '/customer-care/warnings-and-risks',
        'target': '_blank'
    }, 'Warnings and Risks'), '.\n        '), !this.props.inCheckout ? React.createElement('button', {
        'type': 'submit',
        'className': 'btn -wide -blue-2',
        'key': '2289'
    }, '\n          Register\n        ') : null, this.props.inCheckout ? React.createElement('button', {
        'type': 'submit',
        'className': 'btn -wide -orange',
        'key': '2453'
    }, '\n          Continue\n        ') : null), React.createElement('div', { 'className': 'divisor' }, 'or'), React.createElement(FacebookSignIn, {
        'text': 'Register with Facebook',
        'location': this.props.location,
        'inCheckout': this.props.inCheckout
    }), !this.props.inCheckout ? React.createElement('div', {
        'className': 'auth-alt',
        'key': '2820'
    }, '\n        or ', React.createElement(Link, { 'to': '/login' }, 'Sign In'), React.createElement('br', {}), ' if you already have an account\n      ') : null, React.createElement(CaptchaNotice, { 'dark': true })));
}