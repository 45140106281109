import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import stateSelector from 'reduxModules/testimonials/selectors'
import { testimonialsDispatchers as dispatchers } from 'reduxModules/testimonials/dispatchers'

import template from './template.rt'
import './styles.css'

export class Testimonial extends Component {
  componentDidMount() {
    this.props.loadRandomTestimonial()
  }

  render() {
    if (this.props.text) return template.call(this)
    return null
  }
}

Testimonial.propTypes = {
  loadRandomTestimonial: PropTypes.func.isRequired,
  email: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

Testimonial.defaultProps = {
  email: null
}

export default connect(stateSelector, dispatchers)(Testimonial)
