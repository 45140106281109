const plans = [
  {
    cta: 'Free Trial',
    description: 'Access 4 Courses Per Month',
    features: [
      'FREE Access to Live Artist Q&As',
      'Access 4 courses per month',
      '15% OFF live courses',
      'Access to pathways',
      'Watch on your computer, phone, or tablet',
      '15% OFF DVDs, stream purchases & merchandise'
    ],
    id: 7,
    isMostPopular: false,
    name: 'Basic Monthly',
    price: 19.99,
    priceString: '$19.99/Mo'
  },
  {
    cta: 'Free Trial',
    description: 'Unlimited Courses',
    features: [
      'FREE Access to Live Artist Q&As',
      { isHighlight: true, text: 'Unlimited courses' },
      { isHighlight: true, text: '30% OFF live courses' },
      'Access to pathways',
      'Watch on your computer, phone, or tablet',
      { isHighlight: true, text: '30% OFF DVDs, stream purchases & merchandise' }
    ],
    id: 3,
    isMostPopular: false,
    name: 'Premium Monthly',
    price: 59.99,
    priceString: '$59.99/Mo'
  },
  {
    cta: 'Free Trial',
    description: 'Unlimited Courses, FREE Live Course Access, Discounts and More',
    features: [
      'FREE Access to Live Artist Q&As',
      { isHighlight: true, text: 'Unlimited courses' },
      { isHighlight: true, text: 'FREE live course access' },
      'Access to pathways',
      'Watch on your computer, phone, or tablet',
      { isHighlight: true, text: '30% OFF DVDs, stream purchases & merchandise' },
      { isHighlight: true, text: 'Save $359.94 per year (compared with the Premium Monthly Plan)' }
    ],
    id: 4,
    isMostPopular: true,
    name: 'Yearly',
    price: 359.94,
    priceString: '$359.94/Yr'
  },
  {
    cta: 'LEARN MORE',
    description: 'Significant Savings for Groups of 5 or More',
    features: [
      'FREE Access to Live Artist Q&As',
      'Customizable curriculums',
      'Live course discounts',
      'Access to pathways',
      'Watch on your computer, phone, or tablet',
      '30% OFF DVDs, stream purchases & merchandise',
      'Significant savings'
    ],
    id: 9,
    isMostPopular: false,
    name: 'Group Learning',
    priceString: 'Let\'s Talk'
  }
]

export default plans
