import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { subscriptionPlansDispatchers as dispatchSelector } from 'reduxModules/subscriptions/dispatchers'

import './styles.css'
import template from './template.rt'

export class SubscriptionPlanBox extends Component {
  constructor(props) {
    super(props)
    this.state = { showFeatures: false }
  }
  getCTA() {
    if (this.props.name === 'Group Learning')
      return this.props.cta
    else if (!this.props.usedFreeTrial)
      return this.props.cta
    else
      return 'Subscribe'
  }
  CTAAction() {
    if (this.props.name === 'Group Learning')
      window.location.href = 'https://pages.stanwinstonschool.com/group-subscription-info/'
    else {
      const { cta, description, features, id, isMostPopular, name, price }
        = this.props
      const plan
        = { cta, description, features, id, isMostPopular, name, price }
      this.props.selectPlan(plan)
    }
  }
  toggleFeatures() {
    this.setState({ showFeatures: !this.state.showFeatures })
  }
  render() {
    return template.call(this)
  }
}

const planProps = {
  cta: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.number.isRequired,
  isMostPopular: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number,
  priceString: PropTypes.string.isRequired
}

SubscriptionPlanBox.propTypes = {
  ...planProps,
  router: PropTypes.object.isRequired,
  selectPlan: PropTypes.func.isRequired,
  usedFreeTrial: PropTypes.bool.isRequired
}

export default connect(
  null,
  dispatchSelector,
  (stateProps, dispatchProps, ownProps) => (
    { selectPlan: dispatchProps.selectPlan , ...ownProps }
  )
)(SubscriptionPlanBox)
