import * as React from 'react';
import * as _ from 'lodash';
export default function () {
    return React.createElement('div', { 'className': 'TutorialMaterials' }, React.createElement('div', { 'className': 'TutorialMaterials__content--expanded' }, React.createElement('div', {
        'className': 'formatted',
        'dangerouslySetInnerHTML': { __html: this.props.materials }
    }), this.props.referenceMaterials ? React.createElement('div', {
        'className': 'formatted',
        'dangerouslySetInnerHTML': { __html: this.props.referenceMaterials },
        'key': '203'
    }) : null));
}