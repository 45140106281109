/* globals PROD_ENV */
import PropTypes from 'prop-types'
import { Component } from 'react'
import googleTrackConversion from 'components/utils/googleConversion'
import { connect } from 'react-redux'
import dispatchers from 'reduxModules/home/dispatchers'
import { homeStateSelector as stateSelector } from 'reduxModules/home/selector'
import { CurrentlyWatchingTutorialSummaryPropTypes } from 'components/generic/CourseSummary/propTypes'
import BlogPostStructure from '../Blog/BlogPosts/BlogPost/propTypes'
import template from './template.rt'
import './styles.css'

export class Home extends Component {
  constructor(props) {
    super(props)
    this.getCategoryURL = category =>
      category.tutorialCategorySlug
        ? `/tutorial_categories/${category.tutorialCategorySlug}`
        : `/search?q=${encodeURIComponent(category.name)}`

    this.getBackground = this.getBackground.bind(this)
    this.getMainTitle = this.getMainTitle.bind(this)
  }

  componentDidMount() {
    const {
      closeBannerSubscription,
      loadHome,
      loadBanners,
      loadSecondaryBanners,
      loadBlogPosts,
      loadTutorials,
      loadFBImages,
      loadRecentPathways,
      loadFreeTrialImage,
      loadCurrent,
      loadLatestTutorials
    } = this.props

    closeBannerSubscription()
    loadHome()
    loadBanners()
    loadSecondaryBanners()
    loadBlogPosts({ limit: 3 })
    loadTutorials({ pageSize: 4, featured: true })
    loadRecentPathways()
    loadCurrent()
    loadFreeTrialImage()
    loadFBImages()
    loadLatestTutorials()

    if (PROD_ENV)
      // Google tracking
      googleTrackConversion('home', 10)
  }

  componentWillUnmount() {
    const { clearTutorialList, clearHome, openBannerSubscription } = this.props

    clearTutorialList()
    clearHome()
    openBannerSubscription()
  }

  getMainTitle() {
    const { isSubscribed, freeTrialBanner } = this.props
    if (isSubscribed) {
      if (freeTrialBanner.mainSubscribeTitle)
        return freeTrialBanner.mainSubscribeTitle
      return 'Imagine. Learn. Create.'
    }
    if (freeTrialBanner.mainFreeTrialTitle)
      return freeTrialBanner.mainFreeTrialTitle
    return 'For Artists and Creators'
  }

  getBackground() {
    let background = ''
    const { freeTrialBanner, isMobile } = this.props
    if (freeTrialBanner && freeTrialBanner.bannerImage) {
      if (freeTrialBanner) background = freeTrialBanner.bannerImage.normal
      if (isMobile && freeTrialBanner)
        background = freeTrialBanner.bannerImage.mobile
    }
    return {
      backgroundImage: `url(${background})`
    }
  }

  render() {
    return template.call(this)
  }
}

export const categoryImagesPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  image: PropTypes.shape({
    default: PropTypes.string.isRequired,
    featured: PropTypes.string.isRequired,
    original: PropTypes.string.isRequired
  }).isRequired,
  name: PropTypes.string.isRequired,
  tutorialCategorySlug: PropTypes.string
})

export const metaInformationPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
})

Home.propTypes = {
  blogPosts: PropTypes.arrayOf(PropTypes.shape(BlogPostStructure)),
  isMobile: PropTypes.bool,
  fbImages: PropTypes.arrayOf(
    PropTypes.shape({
      original: PropTypes.string.isRequired
    })
  ),
  loadBlogPosts: PropTypes.func.isRequired,
  loadFBImages: PropTypes.func.isRequired,
  loadTutorials: PropTypes.func.isRequired,
  clearTutorialList: PropTypes.func.isRequired,
  clearHome: PropTypes.func.isRequired,
  loadFreeTrialImage: PropTypes.func.isRequired,
  openBannerSubscription: PropTypes.func.isRequired,
  closeBannerSubscription: PropTypes.func.isRequired,
  loadHome: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  // from state
  // TODO: it's missing `banners` structure
  categoryImages: PropTypes.arrayOf(categoryImagesPropType).isRequired,
  currentlyWatching: PropTypes.shape({
    results: PropTypes.arrayOf(
      PropTypes.shape({
        ...CurrentlyWatchingTutorialSummaryPropTypes,
        confirmWatchTutorial: PropTypes.bool
      })
    )
  }).isRequired,
  metaInformation: PropTypes.arrayOf(metaInformationPropType).isRequired
}

Home.defaultProps = {
  blogPosts: [],
  fbImages: [],
  isMobile: false
}

export default connect(stateSelector, dispatchers)(Home)
