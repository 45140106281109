/* eslint-disable import/prefer-default-export */
import { createStructuredSelector, createSelector } from 'reselect'
import {
  isMobileSelector,
  loadingSelector,
  usedFreeTrialSelector,
  recaptchaSelector
} from 'reduxModules/app/selectors'

export const subscriptionPlansSelector = state => state.subscriptions.plans

export const subscriptionPlansLanguageSelector = state =>
  state.subscriptions.subscriptionPlansLanguage

export const globalPromoSelector = state => state.app.globalPromo

export const isGlobalPromoEnabledSelector = state =>
  state.app.globalPromo.codename && state.app.globalPromo.codename.length > 0

export const subscriptionPlanSelectors = createSelector(
  [
    isMobileSelector,
    usedFreeTrialSelector,
    globalPromoSelector,
    isGlobalPromoEnabledSelector,
    subscriptionPlansLanguageSelector
  ],
  (
    isMobile,
    usedFreeTrial,
    globalPromo,
    isGlobalPromoEnabled,
    subscriptionPlansLanguage
  ) => ({
    isMobile,
    usedFreeTrial,
    globalPromo,
    isGlobalPromoEnabled,
    subscriptionPlansLanguage
  })
)

export const giftSubscriptionSelector = state => ({
  ...state.subscriptions.giftSubscriptionDetail,
  recaptcha: recaptchaSelector(state)
})

export default createStructuredSelector({
  isMobile: isMobileSelector,
  loading: loadingSelector,
  isGlobalPromoEnabled: isGlobalPromoEnabledSelector
})
