import PropTypes from 'prop-types'

import template from './template.rt'
import './styles.css'

export function SubscriptionBanner(props) {
  return template.call({ props })
}

SubscriptionBanner.propTypes = {
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  userIsAuthenticated: PropTypes.bool,
  userIsVerified: PropTypes.bool
}

SubscriptionBanner.defaultProps = {
  isMobile: false,
  userIsAuthenticated: false,
  userIsVerified: false
}

export default SubscriptionBanner
