/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { testimonialListSelector } from 'reduxModules/testimonials/selectors'
import { testimonialsDispatchers } from 'reduxModules/testimonials/dispatchers'
import { testimonialsFetchMore } from 'reduxModules/testimonials'

import LoadMore from '../generic/LoadMore'

import './styles.css'

export function TestimonialList(props) {
  useEffect(() => {
    props.loadTestimonials()
  }, [])

  return (
    <div className="wrapper" id="Testimonials">
      <section className="heading">
        <h1>Testimonials</h1>
      </section>
      <div className="testimonials">
        {props?.testimonials?.results?.map(t => (
          <section id="Testimonial">
            <div className="text">
              <span>
                <i className="icon-quote-open" />
                {t.text}
                <i className="icon-quote-closed" />
              </span>
              <div className="author">{t.fullName}</div>
            </div>
          </section>
        ))}{' '}
        {props?.testimonials?.next ? (
          <div className="load-more">
            <LoadMore
              fetchMoreAction={testimonialsFetchMore}
              next={props?.testimonials?.next}
              listContainerId="testimonials"
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

TestimonialList.propTypes = {
  /* Dispatchers */
  loadTestimonials: PropTypes.func.isRequired,
  testimonials: {
    count: PropTypes.number,
    next: PropTypes.string,
    previous: PropTypes.string,
    results: PropTypes.array
  }
}

TestimonialList.defaultProps = {
  testimonials: {
    count: 0,
    next: null,
    previous: null,
    results: []
  }
}

export default connect(
  testimonialListSelector,
  testimonialsDispatchers
)(TestimonialList)
