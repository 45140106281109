/* global PROD_ENV */
import braintree from 'braintree-web'
import logger from './logger'

const addFraudProtection = (
  callback,
  callbackArgs,
  clientToken,
  showFraudProtectionError
) => {
  braintree.client
    .create({
      authorization: clientToken
    })
    .then(clientInstance => braintree.dataCollector
        .create({
          client: clientInstance,
          kount: true
        })
        .then(dataCollectorInstance => {
          const { deviceData } = dataCollectorInstance
          callback(deviceData, ...callbackArgs)
        })
        .catch(err => {
          // showFraudProtectionError()
          // if (PROD_ENV)
          logger.error(`[Braintree] Error in data collector creation: ${err}`)
        }))
    .catch(err => {
      showFraudProtectionError()
      if (PROD_ENV)
        logger.error(`[Braintree] Error in creation of components: ${err}`)
    })
}

export default addFraudProtection
